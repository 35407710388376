import { useEffect, useRef, useState } from "react";
import Element from "./Element";
import { takeScreenshot } from "helpers";

function Slide({
  slide,
  currentTime,
  playing,
  setPlaying,
  location,
  player,
  lang,
  params,
  creative,
  isActive,
  setActiveSlide,
  creativeState,
  setCreativeState,
}) {
  const style = { opacity: isActive ? 1 : 0, backgroundColor: "#fff" };

  const timer = useRef(0);

  const screenshotTimer = useRef(0);

  useEffect(() => {
    if (isActive && slide.interactiveVisibility?.duration > 0) {
      const { duration, targetSlide } = slide.interactiveVisibility;
      timer.current = setTimeout(() => {
        setActiveSlide(targetSlide - 1);
      }, duration * 1000);
      console.log(`Timeout ${timer.current} para slide ${slide.name}`);
    }

    if (!isActive && timer.current) {
      clearTimeout(timer.current);
      setCreativeState((prev) => ({ ...prev, faceTrackerImageUrl: false }));
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [isActive, setActiveSlide, slide.interactiveVisibility, slide.name]);

  useEffect(() => {
    if (
      isActive &&
      slide.screenshot?.duration > 0 &&
      creativeState.faceTrackerEnabled
    ) {
      const { duration, canvasClass, targetSlide, rotation } = slide.screenshot;
      screenshotTimer.current = setTimeout(() => {
        const canvas = `.screenshot-canvas.screenshot-canvas--${canvasClass}`;
        const callback = (url) => {
          setCreativeState((prev) => ({ ...prev, faceTrackerImageUrl: url }));
        };
        takeScreenshot(canvas, callback, rotation);
        setActiveSlide(targetSlide - 1);
      }, duration * 1000);
      console.log(
        `Timeout ${screenshotTimer.current} para screenshot ${slide.name}`,
      );
    }

    return () => {
      clearTimeout(screenshotTimer.current);
    };
  }, [
    isActive,
    setActiveSlide,
    slide.screenshot,
    slide.name,
    setCreativeState,
    creativeState.faceTrackerEnabled,
  ]);

  return (
    <div className="slide" style={style}>
      {slide.elements.map((element) => {
        return (
          <Element
            key={element.anchor}
            element={element}
            currentTime={currentTime}
            playing={playing}
            setPlaying={setPlaying}
            player={player}
            location={location}
            lang={lang}
            params={params}
            creative={creative}
            setActiveSlide={setActiveSlide}
            isSlideActive={isActive}
            creativeState={creativeState}
            setCreativeState={setCreativeState}
          />
        );
      })}
    </div>
  );
}

export default Slide;

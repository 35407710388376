import React, { useEffect, useRef, useState } from "react";
import { parse } from "query-string";

const { REACT_APP_ENABLED_VIDEO_FORMAT = "none" } = process.env;
// const RELOAD_INTERVAL = 60 * MS;

function VideoElement({
  element,
  player,
  playing,
  visible,
  currentTime,
  onAction,
  setCreativeState,
}) {
  const { sources, style, loop } = element;
  const { preload = "none" } = player || {};
  const query = parse(window.location.search);
  const allFormats = query.allFormats;

  const videoEl = useRef(null);

  const sourcesType =
    REACT_APP_ENABLED_VIDEO_FORMAT && !allFormats
      ? sources.filter((source) =>
          REACT_APP_ENABLED_VIDEO_FORMAT.includes(source.type),
        )
      : sources;

  useEffect(() => {
    if (!videoEl) {
      return;
    }

    let videoRef = videoEl.current;
    // Truco para evitar rotaciones vacías en la creatividad 5b3f11dac9b2f0316c9cc108
    const { source, sources = [] } = element;

    if (query.checkForm && source && query.checkForm === source.form) {
      const urls = sources.filter((s) => !!s.url);

      if (!urls.length) {
        document.location.reload();
      }
    }
    // videoRef.currentTime = 0;

    return function () {
      // videoRef.currentTime = 0;
    };
  }, [element, videoEl, query.checkForm]);

  useEffect(() => {
    if (playing && visible) {
      console.log("restart", currentTime);
      videoEl.current.play();
      setCreativeState((state) => ({
        ...state,
        ...(!state.slideStartTime && { slideStartTime: currentTime }),
      }));
      videoEl.current.addEventListener("ended", () => {
        setCreativeState((state) => ({ ...state, slideStartTime: 0 }));
        onAction();
      });
    } else {
      videoEl.current.pause();
      // videoEl.current.currentTime = 0;
    }
  }, [playing, visible, setCreativeState]);

  function onPause() {
    if (videoEl.current) {
      const stopTime = (videoEl.current.currentTime + 0.4) / 0.8;
      const ref = Math.round(stopTime);
      const distance = Math.abs(ref - stopTime);
      if (distance < 0.02) {
        setTimeout(() => {
          onAction({ targetSlide: 2 });
          videoEl.current.currentTime = 0;
        }, 400);
      }
    }
  }

  return (
    <div>
      {sourcesType.map((source, index) => {
        return (
          <link
            key={index}
            rel="preload"
            as="video"
            href={source.url}
            type={source.type}
          />
        );
      })}
      <video
        onClick={onPause}
        ref={videoEl}
        width={style.width}
        height={style.height}
        preload={preload}
        // loop={loop === undefined ? true : loop}
        muted
      >
        {sourcesType.map((source, index) => {
          return <source key={index} src={source.url} type={source.type} />;
        })}
      </video>
    </div>
  );
}

export default VideoElement;

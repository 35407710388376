import React, { useEffect, useState, useCallback } from "react";
import { parse, stringify } from "query-string";

import Slide from "./components/Slide";

import {
  api,
  headers,
  getProjection,
  isDoubled,
  transformCreative,
  caclTimeline,
  enrichLocation,
  isPreview,
  getPreviewCorners,
} from "./helpers";

import { useLogger } from "./providers/LoggerProvider";

import "./Player.css";

const PLAYER_FRAMERATE = 1 / 30;
const MS = 1000;

function Player({ lang = "es", creativeId }) {
  const [creative, setCreative] = useState(null);
  const [creativeStatus, setCreativeStatus] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  // const [slideTime, setSlideTime] = useState(0);
  const [location, setLocation] = useState(null);
  const [player, setPlayer] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [creativeState, setCreativeState] = useState({});

  const { sendLog } = useLogger();

  window.BroadSignPlay = () => {
    setPlaying(true);
  };

  window.MVisionPlay = () => {
    setPlaying(true);
  };

  window.MVisionPause = () => {
    setPlaying(false);
  };

  const updatePlayback = useCallback(
    function (currentTime) {
      if (playing && creative) {
        const { duration } = creative;
        const nextTime = currentTime + PLAYER_FRAMERATE;

        setCurrentTime(nextTime > duration ? 0 : nextTime);
      }
    },
    [creative, playing],
  );

  useEffect(() => {
    if (!creativeId) {
      return;
    }

    async function fetchCreative() {
      const query = parse(document.location.search);
      const baseUrl = `${api}/player/creativity/${creativeId}`;

      const options = {
        method: "GET",
        headers: headers(),
      };

      const params = {
        ip: query.ip,
        address: query.address,
        bsPlayerId:
          query.ResourceID ||
          query["com_broadsign_suite_bsp_resource_id"] ||
          query["com.broadsign.suite.bsp.resource_id"],
        frameId:
          query.frameId ||
          query.frame_id ||
          query["com.broadsign.suite.bsp.frame_id"],
        lang: query.lang,
      };

      try {
        const response = await window.fetch(
          `${baseUrl}?${stringify(params)}`,
          options,
        );

        if (!response.ok) {
          const responseText = await response.text();
          throw new Error(responseText);
        }

        const data = await response.json();
        setLocation(enrichLocation(data.location));
        setPlayer(data.player);
        setCreative(data.creativity);
        setCreativeStatus(200);
      } catch (error) {
        setLocation(null);
        setPlayer(null);
        setCreative(null);
        setCreativeStatus(500);
        console.error(error);
      }
    }

    fetchCreative();

    if (document.location.search.match(/autoplay/)) {
      setPlaying(true);
    }
  }, [creativeId]);

  useEffect(() => {
    if (creative && creativeStatus === 200) {
      const { slides = [] } = creative;
      const firstSlide = slides[activeSlide] || {};
      const { visibility = {} } = firstSlide;

      if (visibility.duration) {
        const nextSlide = slides[activeSlide + 1] ? activeSlide + 1 : 0;
        setTimeout(() => setActiveSlide(nextSlide), visibility.duration * MS);
      }

      sendLog(
        `Player ${player ? player.bsFrameId : "unknown"} Active slide ${activeSlide}`,
      );
    }
  }, [creative, creativeStatus, activeSlide, sendLog, player]);

  useEffect(() => {
    const delay = PLAYER_FRAMERATE * MS;
    let playbackInterval;

    if (creativeStatus === 200) {
      playbackInterval = setTimeout(() => updatePlayback(currentTime), delay);
    }

    return function () {
      clearTimeout(playbackInterval);
    };
  }, [updatePlayback, creativeStatus, currentTime]);

  useEffect(() => {
    const delay = PLAYER_FRAMERATE * MS;
    let playbackInterval;

    function updatePlayback() {
      if (playing && creative) {
        const { duration } = creative;
        const nextTime = currentTime + PLAYER_FRAMERATE;

        setCurrentTime(nextTime > duration ? 0 : nextTime);
      }
    }

    if (creativeStatus === 200) {
      playbackInterval = setTimeout(updatePlayback, delay);
    }

    return function () {
      clearTimeout(playbackInterval);
    };
  }, [creative, playing, creativeStatus, currentTime]);

  function syncPlayback(currentTime) {
    if (playing) {
      setCurrentTime(currentTime);
    }
  }

  function renderPlayer(className = "player", style = {}, double) {
    if (double || (player && player.double)) {
      return (
        <div
          className="doubled"
          data-creative-id={creative._id}
          data-creative-name={creative.name}
        >
          <div className={className} style={transformCreative(player, style)}>
            {creative.slides.map((slide) => {
              return (
                <Slide
                  key={slide.name}
                  slide={slide}
                  currentTime={currentTime}
                  playing={playing}
                  setPlaying={setPlaying}
                  // syncPlayback={syncPlayback}
                  player={player}
                  location={location}
                  lang={lang}
                  params={parse(window.location.search)}
                  creative={creative}
                  activeSlide={activeSlide}
                  setActiveSlide={setActiveSlide}
                  creativeState={creativeState}
                  setCreativeState={setCreativeState}
                />
              );
            })}
          </div>
          <div className={className} style={transformCreative(player, style)}>
            {creative.slides.map((slide) => {
              return (
                <Slide
                  key={slide.name}
                  slide={slide}
                  currentTime={currentTime}
                  playing={playing}
                  setPlaying={setPlaying}
                  // syncPlayback={syncPlayback}
                  player={player}
                  location={location}
                  lang={lang}
                  params={parse(window.location.search)}
                  creative={creative}
                  activeSlide={activeSlide}
                  setActiveSlide={setActiveSlide}
                  creativeState={creativeState}
                  setCreativeState={setCreativeState}
                />
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div
        className={className}
        style={transformCreative(player, style)}
        data-creative-id={creative._id}
        data-creative-name={creative.name}
      >
        {creative.slides.map((slide, index) => {
          return (
            <Slide
              key={slide.name}
              slide={slide}
              currentTime={currentTime}
              playing={playing}
              setPlaying={setPlaying}
              // syncPlayback={syncPlayback}
              player={player}
              location={location}
              lang={lang}
              params={parse(window.location.search)}
              creative={creative}
              isActive={activeSlide === index}
              setActiveSlide={setActiveSlide}
              creativeState={creativeState}
              setCreativeState={setCreativeState}
            />
          );
        })}
      </div>
    );
  }

  if (creative) {
    const { width, height } = creative.format;
    const style = { width, height };

    if (isPreview()) {
      const corners = getPreviewCorners();
      const transform = getProjection(width, height, corners);
      const previewStyle = { ...style, transform };

      return (
        <div className="preview" style={{ height: window.innerHeight }}>
          <div className="glass" style={previewStyle} />
          {renderPlayer("screen", previewStyle)}
        </div>
      );
    }

    return renderPlayer(
      `player${isDoubled() ? " doubled" : ""}${isDoubled() ? " doubled" : ""}`,
      style,
    );
  } else if (creativeStatus >= 400) {
    return <div className="error_screen">Error {creativeStatus}</div>;
  }

  return <div className="loading" />;
}

export default Player;
